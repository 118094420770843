define("discourse/plugins/discourse-follow/discourse/controllers/feed", ["exports", "@ember/controller", "discourse/lib/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeedController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "viewingSelf", [(0, _computed.propertyEqual)("model.user.id", "currentUser.id")]))();
    #viewingSelf = (() => (dt7948.i(this, "viewingSelf"), void 0))();
  }
  _exports.default = FeedController;
});