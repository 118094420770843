define("discourse/plugins/discourse-gamification/discourse/components/period-input", ["exports", "@ember/object", "@ember-decorators/component", "I18n", "select-kit/components/combo-box", "select-kit/components/select-kit", "discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard"], function (_exports, _object, _component, _I18n, _comboBox, _selectKit, _gamificationLeaderboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PeriodInput = dt7948.c(class PeriodInput extends _comboBox.default {
    get content() {
      let periods = [];
      periods = periods.concat(_gamificationLeaderboard.LEADERBOARD_PERIODS.map((period, index) => ({
        name: _I18n.default.t(`gamification.leaderboard.period.${period}`),
        id: index
      })));
      return periods;
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
  }, [(0, _component.classNames)("period-input", "period-input"), (0, _selectKit.pluginApiIdentifiers)("period-input"), (0, _selectKit.selectKitOptions)({
    filterable: true,
    allowAny: false
  })]);
  var _default = _exports.default = PeriodInput;
});