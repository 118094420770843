define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-show-discourse-gamification-leaderboards-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-gamification/discourse/components/modal/recalculate-scores-form"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _I18n, _recalculateScoresForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseGamificationLeaderboardsIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    creatingNew = false;
    sortedLeaderboards(leaderboards) {
      return leaderboards?.sortBy("updatedAt").reverse() || [];
    }
    static #_4 = (() => dt7948.n(this.prototype, "sortedLeaderboards", [(0, _decorators.default)("model.leaderboards.@each.updatedAt")]))();
    resetNewLeaderboard() {
      this.set("creatingNew", false);
    }
    static #_5 = (() => dt7948.n(this.prototype, "resetNewLeaderboard", [_object.action]))();
    destroyLeaderboard(leaderboard) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("gamification.leaderboard.confirm_destroy"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
            type: "DELETE"
          }).then(() => {
            this.toasts.success({
              duration: 3000,
              data: {
                message: _I18n.default.t("gamification.leaderboard.delete_success")
              }
            });
            this.model.leaderboards.removeObject(leaderboard);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "destroyLeaderboard", [_object.action]))();
    recalculateScores() {
      this.modal.show(_recalculateScoresForm.default, {
        model: this.model
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "recalculateScores", [_object.action]))();
    parseDate(date) {
      if (date) {
        // using the format YYYY-MM-DD returns the previous day for some timezones
        return date.replace(/-/g, "/");
      }
    }
  }
  _exports.default = AdminPluginsShowDiscourseGamificationLeaderboardsIndexController;
});